jQuery(document).ready(function () {





  // Sticky elements.





  
  

  jQuery(".fp-hero").imagesLoaded(function () {
    // Animate it for coolness
    

    // Hero fade in
    var hr = jQuery(".fp-hero hr.mini");
    var h1 = jQuery(".fp-hero h1");
    var p = jQuery(".fp-hero p.desc");
    var heroTween = new TimelineMax();

    heroTween
      //.fromTo(hr, 0.5, { opacity: 0 }, { delay: 2, opacity: 1 })
      .fromTo(hr, 0.4, { scaleX: 1, rotate: 90, opacity: 0 }, { delay: .2, scaleX: 3, rotate: 0, opacity: 1 })
      .fromTo(h1, 0.4, { opacity: 0, top: "15px" }, { delay: .2, opacity: 1, top: 0 })
      .fromTo(p, 0.4, { opacity: 0, top: "-15px" }, { delay: .2, opacity: 1, top: 0 })
      .fromTo(hr, 0.4, { scaleX: 3 }, { scaleX: 1})
      .play();
    
    
    //setTimeout(function () {
      jQuery(".fp-hero").addClass("inplace");
    //}, 100);

  });




  // Animated icons
  var controller = new ScrollMagic.Controller();

  var animateShape1 = Array.prototype.slice.call(
    document.querySelectorAll(".two-col-animated-icons")
  );

  var self = this;

  animateShape1.forEach(function (self) {

    console.log('HERE');
    
    var shape1 = self.querySelectorAll(".two-col-animated-icons .shapes1 #shape-triangle");
    var shape2 = self.querySelectorAll(".two-col-animated-icons .shapes1 #shape-circle");
    var shape3 = self.querySelectorAll(".two-col-animated-icons .shapes1 #shape-square");

    console.log('HERE');
    console.log(shape1);

    var sceneOne = new TimelineMax();

    sceneOne.fromTo(shape1, 0.5, { y: "-10%", x: "5%" }, { y: "20%" }, shape1)
    .fromTo(shape2, 0.5, { y: "0", x: "-10%" }, { y: "10%", x: "30%" }, shape1)
    .fromTo(shape3, 0.5, { y: "-20%", x: "-8%" }, { y: "50%", x: "7%" }, shape1);

    var scene1 = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 10000,
      offset: 0
    })
      .setTween(sceneOne)
      .setClassToggle(self, "active")
      .addTo(controller);
  });


/*
  var offset = 0;
  var services_height = jQuery('#services-container').height() - 400;

  var scene = new ScrollMagic.Scene({
    triggerElement: ".services-floater",
    duration: services_height,
    triggerHook: "onLeave",
    offset: offset
  }).setPin(".services-floater h2")
		.addIndicators({name: "1 (duration: "+services_height+")"}) // add indicators (requires plugin)
		.addTo(controller);
    */












/*
  jQuery(window).scroll(jQuery.throttle(65, updateStickyElements));
  jQuery(window).on("debouncedresize", function (event) {
    // Your event handler code goes here.
    updateStickyElements();
  });
  */

});

/*
var sticky_services_stuck = false;
var sticky_services_from_top = 0;
function updateStickyElements() { 

  var from_top = jQuery(window).scrollTop();

  if (jQuery('.services-floater h2').length > 0) {
    sticky_services_from_top = jQuery('.services-floater').offset().top;
  }

  if (sticky_services_from_top > 0) {
    if (!sticky_services_stuck && from_top >= sticky_services_from_top) {
      jQuery('.services-floater').addClass('sticky--pinned');
      sticky_services_stuck = true;
    }
    else if (sticky_services_stuck && from_top < sticky_services_from_top) {
      jQuery('.services-floater').removeClass('sticky--pinned');
      sticky_services_stuck = false;
    }

    // If it goes beyond the end of our set container, maybe just fade it out or something.
  }

}*/